import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layouts/index';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const Testimonial = ({ data }) => {
  const title = data.mdx.frontmatter.title;
  const body = data.mdx.body;
  return (
    <Layout bodyClass="page-testimonial">
      <div className="strip strip-white strip-diagonal">
        <div className="container22 pt-4 pt-md-10">
          <div className="row justify-content-start">
            <div className="col-12">
              <div className="testimonial testimonial-single">
                <h1 className="title">{title}</h1>
                <MDXRenderer>{data.mdx.body}</MDXRenderer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        path
      }
      body
    }
  }
`;

export default Testimonial;
